import { useEffect, useRef, useState } from "react";
import { cipriPhotos, PhotoModel, TIMEBETWEENPHOTOS } from "../constants";
import { getRandomNumber } from "../helpers/utils";
import "./Background.scss";

export function Background({ children }: { children: React.ReactNode }) {
  let photos = useRef(cipriPhotos);
  const photosLength = photos.current.length;
  // set a different photo every 8 seconds
  const [photoIndex, setPhotoIndex] = useState(0);

  const [startSlide, setStartSlide] = useState(false);

  const renderPhoto = (index: number) => {
    const node = photos.current[index].img;
    if (node) {
      if (document.getElementById("container")?.firstChild) {
        document.getElementById("container")?.replaceChildren(node);
      } else {
        document.getElementById("container")?.appendChild(node);
      }
      if (index === 0) {
        document.getElementById("container")?.classList.add("photoContainer");
      }
    }
  };

  useEffect(() => {
    // load all the photos into memory
    const preloadPhotos = async () => {
      const loadPhoto = (photo: PhotoModel): Promise<PhotoModel> => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = photo.src;
          img.className = "photo";
          img.onload = () => {
            resolve({ ...photo, img: img });
          };
        });
      };

      // load the first photo to show it as soon as possible, then run the renderPhoto  and setStartSlide functions and then load the rest of the photos
      photos.current[0] = await loadPhoto(photos.current[0]);
      renderPhoto(0);
      setStartSlide(true);
      for (let i = 1; i < photos.current.length; i++) {
        photos.current[i] = await loadPhoto(photos.current[i]);
      }
    };

    preloadPhotos();
  }, []);

  useEffect(() => {
    if (startSlide) {
      setTimeout(() => {
        setPhotoIndex((index) => {
          const newIndex = (index + 1) % photosLength;
          renderPhoto(newIndex);
          return newIndex;
        });
      }, TIMEBETWEENPHOTOS);
    }
  }, [startSlide, photosLength, photoIndex]);

  // get a random position for the photo
  const [position, setPosition] = useState(getRandomNumber());
  useEffect(() => {
    setPosition(getRandomNumber());
  }, [photoIndex]);

  return (
    <div className="background">
      {children}
      <div
        className="shadow"
        id="container"
        style={{
          [position.verticalValue]: position.verticalPercentage + "%",
          [position.horizontalValue]: position.horizontalPercentage + "%",
        }}
      ></div>
    </div>
  );
}
