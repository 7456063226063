// function that returns a random number between 0 and 50 and random top,left, bottom and right
export const getRandomNumber = (): {
  horizontalPercentage: number;
  verticalPercentage: number;
  horizontalValue: "left" | "right";
  verticalValue: "top" | "bottom";
} => {
  const horizontalPercentage = Math.floor(Math.random() * 50);
  const verticalPercentage = Math.floor(Math.random() * 50);
  const horizontalValue = Math.random() < 0.5 ? "left" : "right";
  const verticalValue = Math.random() < 0.5 ? "top" : "bottom";

  return {
    horizontalPercentage,
    verticalPercentage,
    horizontalValue,
    verticalValue,
  };
};
