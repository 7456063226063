export interface PhotoModel {
  src: string;
  img?: HTMLImageElement;
}

const photos: PhotoModel[] = [];
for (let i = 1; i < 51; i++) {
  photos.push({
    src: `./images/photo${i}.jpg`,
  });
}

export const TIMEBETWEENPHOTOS = 8000;

export const cipriPhotos = photos;

export const bornDate = new Date(1992, 5, 1);

export const deathDate = new Date(2022, 8, 23);
