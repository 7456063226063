import "./App.scss";
import { Background } from "./components/Background";
import { Header } from "./components/Header";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    text: {
      primary: "#fff",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Background>
        <Header />
      </Background>
    </ThemeProvider>
  );
}

export default App;
