import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import format from "date-fns/format";
import { bornDate, deathDate } from "../constants";
export const Header = () => {
  const smallScreen = useMediaQuery("(max-width:550px)");
  return (
    <Stack
      sx={{
        minHeight: "100vh",
      }}
      direction="column"
      gap={5}
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant={smallScreen ? "h4" : "h2"}
        color={"white"}
        sx={{
          textAlign: "center",
          textShadow:
            "0px 0px 10px rgba(206,206,206,0.41), 0px 0px 10px rgba(206,206,206,0.41)",
        }}
      >
        Farewell Cipriano
      </Typography>
      <Typography
        variant="h4"
        color={"white"}
        sx={{
          textAlign: "center",
          textShadow:
            "0px 0px 10px rgba(206,206,206,0.41), 0px 0px 10px rgba(206,206,206,0.41)",
        }}
      >
        {format(bornDate, "dd / MM / yyyy")}
        <br />
        {format(deathDate, "dd / MM / yyyy")}
      </Typography>
    </Stack>
  );
};
